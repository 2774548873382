import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import MetaTags from 'react-meta-tags';
import SurveyForm from './components/survey';
import Header from './components/header';
import { clientUrl, ogDescription, ogTitle } from './config';

function App() {
  return (
    <>
      <div className="App">
          <MetaTags>
            <title>{ogTitle}</title>
            <meta id="og-title" property="og:title" content={ogTitle} />
            <meta id="og-image" property="og:image" content={`${clientUrl}/osypchik.jpg`} />
            <meta id="og-description" property="og:description" content={ogDescription} />
            <meta id="meta-description" name="description" content={ogDescription} />
          </MetaTags>
          <Header />
          <SurveyForm />
      </div>
    </>
  );
}

export default App;
