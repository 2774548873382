import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { apiUrl } from '../config';
import { useEffectOnce } from 'react-use';
import {CopyToClipboard} from 'react-copy-to-clipboard';

// import en from '../i18n/locales/en/translations.json'
import keys from '../keys.json';

const ALLOW_EMPTY = false; // used for testing
// const ALLOW_EMPTY = true;

const cardNumber = '5375 4112 0649 1438';

const instaUsernameRe = new RegExp('^@?[a-zA-Z0-9_][a-zA-Z0-9_.]*$');

const phoneRe = new RegExp('^[\s()+-]*([0-9][\s()+-]*){6,20}$');

const SurveyForm = () => {
    const { t } = useTranslation();
    const [postData, setPostData] = useState({});
    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [paid, setPaid] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [showReceipt, setShowReceipt] = useState(false);

    const [copyClicked, setCopyClicked] = useState(false);

    const checkErrors = () => {
        const keys = Object.keys(errors);
        for (let i = 0; i < keys.length; i++) {
            if (errors[keys[i]] !== '') {
                return true;
            }
        }
        return false;
    }

    const reset = () => {
        localStorage.removeItem('formData');
        localStorage.removeItem('paid');
        setPaid(false);
        setShowReceipt(false);
        init();
    }

    const checkEmpty = () => {
        if (ALLOW_EMPTY) {
            return false;
        }
        const keys = Object.keys(postData);
        let isError = false;
        const newErrors = { ...errors };
        for (let i = 0; i < keys.length; i++) {
            if (postData[keys[i]] === '') {
                newErrors[keys[i]] = t('empty_field');
                isError = true;
            }
        }
        setErrors(newErrors);
        return isError;
    }

    const changeField = (key, newValue) => {
        const newPostData = { ...postData };
        newPostData[key] = newValue;
        setPostData(newPostData);
        localStorage.setItem("formData", JSON.stringify(newPostData));
        if (newValue?.length === 0) {
            const newErrors = { ...errors };
            newErrors[key] = t('empty_field');
            setErrors(newErrors);
            return;
        } else {
            const newErrors = { ...errors };
            newErrors[key] = '';
            setErrors(newErrors);
        }
        if (key === 'instagram_nickname') {
            const match = instaUsernameRe.test(newValue);
            const newErrors = { ...errors };
            if (!match) {
                newErrors[key] = t('ig_username_invalid');
                setErrors(newErrors);
            } else {
                newErrors[key] = '';
                setErrors(newErrors);
            }
        } else if (key === 'phone_number') {
            const match = phoneRe.test(newValue);
            const newErrors = { ...errors };
            if (!match) {
                newErrors[key] = t('phone_invalid');
                setErrors(newErrors);
            } else {
                newErrors[key] = '';
                setErrors(newErrors);
            }
        }
    }

    const onApproved = (response) => {
        console.log('onApproved');
        console.log(response);
        localStorage.setItem("paid", 'true');
        setPaid(true);
    }

    const onDeclined = (response) => {
        console.log('onDeclined');
        console.log(response);
    }

    const onProcessing = (response) => {
        console.log('onProcessing');
        console.log(response);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (checkEmpty()) {
            return;
        }
        console.log('Submit!');

        let json = {};
        try {
            let response = await fetch(`${apiUrl}/submit`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(postData)
            });
            json = await response.json();
            console.log(json);

            if (json.error) {
                console.error(json);
                setError(t('internal server error'));
                setDisabled(true);
                return;
            }
        } catch (err) {
            console.error(err);
            setError(t('internal server error'));
            setDisabled(true);
            return;
        }

        setShowReceipt(true);
        localStorage.setItem("paid", 'true');
        return;

        // response = await fetch(`${apiUrl}/wayforpay`, {
        //     method: 'POST',
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //         first_name: 'Vasya', // TODO
        //         last_name: 'Pupkin', // TODO
        //     })
        // });
        // const data = await response.json();
        // console.log(data);

        // merchantAccount, merchantDomainName, orderReference, orderDate, amount, currency, productName [0], productName [1] ..., productName [n], productCount [0], productCount [1], ..., productCount [n], productPrice [0], productPrice [1], ..., productPrice [n] розділених ";" 

        // eslint-disable-next-line no-undef
        const wayforpay = new Wayforpay(); 	
        const pay = () => { 		
            wayforpay.run(json.data, onApproved, onDeclined, onProcessing);
            // wayforpay.run({ 				
            //     merchantAccount : "test_merch_n1", 				
            //     merchantDomainName : "www.market.ua", 				
            //     authorizationType : "SimpleSignature", 				
            //     merchantSignature : "b95932786cbe243a76b014846b63fe92", 				
            //     orderReference : "DH783023", 				
            //     orderDate : "1415379863", 				
            //     amount : "1547.36", 				
            //     currency : "UAH", 				
            //     productName : "Процессор Intel Core i5-4670 3.4GHz", 				
            //     productPrice : "1000", 				
            //     productCount : "1", 				
            //     clientFirstName : "Вася", 				
            //     clientLastName : "Васечкин", 				
            //     clientEmail : "some@mail.com", 				
            //     clientPhone: "380631234567", 				
            //     language: "UA" 			
            // });
        };

        pay();

    };

    const init = () => {
        if (localStorage.getItem("paid") === 'true') {
            setPaid(true);
            setShowReceipt(true);
            return;
        }

        const newPostData = { ...postData };
        for (let i = 0; i < keys.length; i++) {
            newPostData[keys[i]] = '';
        }
        setErrors(newPostData);

        const storedData = localStorage.getItem("formData");
        if (storedData !== null) {
            setPostData({ ...JSON.parse(storedData) });
        } else {
            setPostData(newPostData);
        }

        const script = document.createElement("script");

        script.src = "/pay-widget.js";
        script.async = true;
    
        document.body.appendChild(script);
        setInitialized(true);
    }

    useEffectOnce(() => {
        console.log(process.env.NODE_ENV);
        init();
    });

    const sleep = async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const onCopy = async () => {
        setCopyClicked(true);
        await sleep(500);
        setCopyClicked(false);
    };

    return (
        <Container>
            {initialized && showReceipt === false && paid === false &&
                <Form onSubmit={onSubmit} className='survey-form mt-sm-3 col-md-6">'>
                    {keys.map((key, index) => {
                        return (
                            <Form.Group key={`${key}-${index}`} controlId={`form.${key}`} className="mt-sm-3">
                                {key === "something_that_you_love" && <h3>{t('goal_and_path_to')}</h3>}
                                {key === "getting_the_feedback_at_the_wrong_time" && <h3>{t('what_should_the_coach_do_in_case_of')}</h3>}
                                <Form.Label>{t(key)}</Form.Label>
                                {/* <Form.Control type="email" placeholder="name@example.com" /> */}
                                {/* <Form.Control type="text" required onChange={e => {
                                    changeField(key, e.target.value);
                                }} /> */}
                                <Form.Control disabled={disabled} isInvalid={(errors?.[key] || '').length !== 0} value={postData?.[key] || ''} type="text" onChange={e => {
                                    changeField(key, e.target.value);
                                }} />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.[key]}
                                </Form.Control.Feedback>

                            </Form.Group>
                        );
                    })}
                    <Container>
                        {error && <h3 className='text-center text-danger'>{t('internal_error')}</h3>}
                        <Row className=''>
                            <Col className="text-center submit-button">
                                <Button type='submit' disabled={disabled || checkErrors()} className="mt-3 mb-3">{t('submit')}</Button>
                            </Col>
                        </Row>
                    </Container>
                    {/* <Form.Group controlId="form.Name">
                        <Form.Label>{t('name')}</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" />
                    </Form.Group>
                    <Form.Group controlId="form.Email" className="mt-sm-3">
                        <Form.Label>{t('email')}</Form.Label>
                        <Form.Control type="email" placeholder="name@example.com" />
                    </Form.Group>
                    <Form.Group controlId="form.Textarea" className="mt-sm-3">
                        <Form.Label>{t('comment')}</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>
                    <Button type='submit' className="mt-sm-3">{t('submit')}</Button> */}
                </Form>
            }
            {/* {paid === true &&
                <Row>
                    <Col className='mt-5 text-center'>
                        <p className='already-paid'>{t('already_paid')}</p>
                        <p className='wait-for-analysis'>{t('wait_for_analysis')}</p>
                        <Button onClick={reset} variant="link" className='fill-another'>{t('fill_another')}</Button>
                    </Col>
                </Row>
            } */}
            {showReceipt === true &&
            <>
                <Row>
                    <Col className='mt-5 text-center'>
                        <p className='already-paid'>{t('survey_sent')}</p>
                        {/* <p className='already-paid'>{t('already_paid')}</p> */}
                    </Col>
                </Row>

                <Row>
                    <Col className='mt-2 text-center'>
                        {t('amount')}
                    </Col>
                    <Col className='mt-2 text-center'>
                        500 UAH
                    </Col>
                </Row>

                <Row>
                    <Col className='mt-2 text-center'>
                        {t('card_number')}
                    </Col>
                    <Col className='mt-2 text-center'>
                        <div className={`card-number-with-copy ${copyClicked ? 'blink-text' : ''}`}>
                            <span>
                                <div>{cardNumber}</div>
                                <CopyToClipboard text={cardNumber}
                                    onCopy={onCopy}>
                                    <img src='/copy.png' alt='copy' title="Копіювати" className='copy-button'></img>
                                </CopyToClipboard>
                                {/* <div className="copied-notif">Copied!</div> */}
                            </span>
                        </div>
                    </Col>

                </Row>

                <Row>
                    <Col className='mt-2 text-center'>
                        {t('bank_url')}
                    </Col>
                    <Col className='mt-2 text-center'>
                        <a href="https://send.monobank.ua/jar/9AqCBGXJKM" target="_blank">https://send.monobank.ua/jar/9AqCBGXJKM</a>
                    </Col>
                </Row>

                <Row>
                    <Col className='mt-3 text-center'>
                        <p className='comment-hint'>{t('comment_hint')}</p>
                        {/* <p className='already-paid'>{t('already_paid')}</p> */}
                    </Col>
                </Row>

                <Row>
                    <Col className='text-center'>
                        <img src='/qr.png' alt='monobank-qr-code' title="Посилання на банку" className='mono-qr' onClick={() => console.log('click')}></img>
                    </Col>
                    {/* <iframe src="https://send.monobank.ua/widget.html?jar=4FEmMZzqgfrFQ1EAjGfdXbDRxENc1PaD&sendId=9AqCBGXJKM&type=qr" title="Monobank iframe"></iframe> 
                    <div className="widget qr-container" style={{height: '323px'}}>
                        <div className="qr">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" shape-rendering="crispEdges"><path fill="#ffffff" d="M0 0h29v29H0z"></path><path stroke="#000000" d="M0 0.5h7m4 0h1m1 0h4m2 0h1m2 0h7M0 1.5h1m5 0h1m1 0h2m3 0h3m1 0h3m2 0h1m5 0h1M0 2.5h1m1 0h3m1 0h1m2 0h1m1 0h1m1 0h1m1 0h1m3 0h1m2 0h1m1 0h3m1 0h1M0 3.5h1m1 0h3m1 0h1m3 0h1m1 0h1m3 0h1m1 0h1m3 0h1m1 0h3m1 0h1M0 4.5h1m1 0h3m1 0h1m1 0h1m1 0h2m1 0h2m3 0h3m1 0h1m1 0h3m1 0h1M0 5.5h1m5 0h1m3 0h1m1 0h6m1 0h2m1 0h1m5 0h1M0 6.5h7m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h1m1 0h7M9 7.5h1m1 0h4m1 0h3M0 8.5h1m1 0h1m1 0h1m1 0h1m3 0h4m2 0h5m3 0h1m2 0h1M1 9.5h1m1 0h1m5 0h2m1 0h2m6 0h4m1 0h1m2 0h1M0 10.5h4m1 0h3m2 0h3m1 0h1m1 0h2m2 0h2m2 0h1m1 0h3M0 11.5h1m2 0h1m3 0h1m1 0h1m2 0h2m3 0h2m1 0h3m4 0h1M1 12.5h2m1 0h1m1 0h1m2 0h2m3 0h2m1 0h1m1 0h4m2 0h1m1 0h2M2 13.5h2m1 0h1m1 0h2m1 0h1m1 0h1m7 0h3m2 0h1m2 0h1M0 14.5h2m1 0h4m1 0h2m1 0h1m1 0h1m2 0h3m2 0h1m3 0h1m1 0h2M0 15.5h2m3 0h1m1 0h2m3 0h1m2 0h1m1 0h1m1 0h1m4 0h2m1 0h1M1 16.5h1m3 0h4m2 0h2m3 0h2m2 0h3m2 0h1m1 0h2M1 17.5h2m2 0h1m5 0h3m3 0h1m2 0h3m2 0h2m1 0h1M0 18.5h1m2 0h1m1 0h3m3 0h2m1 0h1m3 0h1m1 0h2m1 0h2m2 0h2M1 19.5h3m3 0h1m4 0h4m1 0h2m3 0h1m2 0h1m1 0h1M0 20.5h1m3 0h4m1 0h1m1 0h2m1 0h1m2 0h1m2 0h5M8 21.5h1m1 0h2m2 0h1m1 0h5m3 0h1m1 0h3M0 22.5h7m2 0h1m2 0h2m3 0h4m1 0h1m1 0h2m1 0h2M0 23.5h1m5 0h1m2 0h1m1 0h2m2 0h1m1 0h1m2 0h1m3 0h2m1 0h1M0 24.5h1m1 0h3m1 0h1m1 0h2m6 0h3m1 0h5m3 0h1M0 25.5h1m1 0h3m1 0h1m2 0h1m1 0h3m1 0h2m3 0h1m2 0h2m1 0h3M0 26.5h1m1 0h3m1 0h1m1 0h2m4 0h1m2 0h1m1 0h1m3 0h3m2 0h1M0 27.5h1m5 0h1m3 0h2m1 0h3m1 0h4m1 0h1m1 0h1m2 0h1M0 28.5h7m1 0h3m1 0h1m1 0h2m1 0h4m3 0h2m1 0h2"></path></svg>
                        </div>
                    </div> */}
                </Row>
                <Row>
                    <Col className='mt-2 text-center'>
                        <p className='wait-for-analysis'>{t('wait_for_analysis')}</p>
                        <Button onClick={reset} variant="link" className='fill-another'>{t('fill_another')}</Button>
                    </Col>
                </Row>
            </>
            }
        </Container>
    );
}

export default SurveyForm;
