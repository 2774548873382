import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    }

    return (
        <>
            <Container className='country-selector'>
                <div className='header d-flex justify-content-center flex-nowrap col-md-6 mt-3 mx-auto'>
                    <Row>
                        <Col>
                            <img src='/ua.svg' alt='ua' title="Українська" className='country-circle' onClick={() => changeLanguage('ua')}></img>
                        </Col>
                        <Col>
                            <img src='/en.svg' alt='en' title="English" className='country-circle' onClick={() => changeLanguage('en')}></img>
                        </Col>
                        {/* <Col>
                            <img src='/ru.svg' alt='ru' title="Русский" className='country-circle' onClick={() => changeLanguage('ru')}></img>
                        </Col> */}
                    </Row>
                </div>
            </Container>
        </>
    );
}

export default Header;